import React from "react";
import { useCommonTranslations } from "../components/CommonTranslations";
import Layout from "../components/layout";
import { PastScreenings, Screenings } from "../components/Screenings";
import Seo from "../components/seo";
import { WatchNowBanner } from "../components/WatchNowBanner";

export default function FestivalScreenings({ location }: { location: Location }) {
  const commonTranslations = useCommonTranslations();
  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title={commonTranslations.festivalScreenings} />
      <div className="grid grid-cols-12 min-h-screen pt-32">
        <div className="col-span-10 col-start-2">
          <Screenings />
        </div>
        <div className="col-span-12 w-full md:w-1/2 mx-auto h-[2px] bg-white my-20"></div>
        <div className="col-span-10 col-start-2 mb-16">
          <PastScreenings />
        </div>
      </div>
    </Layout>
  );
}
